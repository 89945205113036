// import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState, lazy, Suspense, Profiler } from "react";
import lozad from 'lozad'
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { useAuth } from "context/authContext";

import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Layout from "./layout";
const Layout = lazy(() => import("./layout"));
const Connect = lazy(() => import("./pages/connect/Connect"));
const CreatePassword = lazy(() =>
  import("./pages/createPassword/CreatePassword")
);
const CreatePost = lazy(() => import("./pages/createPost/CreatePost"));
const ForgotPassword = lazy(() =>
  import("./pages/forgotPassword/ForgotPassword")
);
const Home = lazy(() => import("./pages/home/Home"));
const Community = lazy(() => import("./pages/Community/Community"));
const Inventory = lazy(() => import("./pages/inventory/Inventory"));
const Login = lazy(() => import("./pages/login/Login"));
const Notification = lazy(() => import("./pages/notification/Notification"));
const PageNotFound = lazy(() => import("./pages/pageNotFound/PageNotFound"));
const PostDetails = lazy(() => import("./pages/postDetails/PostDetails"));
const Privacy = lazy(() => import("./pages/privacy/Privacy"));

const Profile = lazy(() => import("./pages/profile/Profile"));
const Register = lazy(() => import("./pages/register/Register"));
const Search = lazy(() => import("./pages/search/Search"));
const Setting = lazy(() => import("./pages/setting/Setting"));
const Support = lazy(() => import("./pages/support/Support"));
const AcceptablePolicy = lazy(() =>
  import("./pages/Terms&Conditions/Acceptable-Policy")
);
const CookiePolicy = lazy(() =>
  import("./pages/Terms&Conditions/Cookie-Policy")
);
const PrivacyNotice = lazy(() =>
  import("./pages/Terms&Conditions/Privacy-Notice")
);
const TermsConditions = lazy(() =>
  import("./pages/Terms&Conditions/Terms-Conditions")
);
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
import "./styles/globalStyles.css";
const OTP = lazy(() => import("pages/otp"));
const PropertyWantedListing = lazy(() =>
  import("./pages/propertyWantedListing/CreatePost")
);

const IntegrationSetting = lazy(() =>
  import("./pages/setting/IntegrationSetting")
);
const EmailSetting = lazy(() => import("./pages/setting/EmailSetting"));
const PaymentConfirmation = lazy(() => import("./pages/PaymentConfirmation"));

// const WebappSettings = lazy(() => import("./pages/setting/WebappSetttings"));
const Test = lazy(() => import("./pages/test"));
const SignTemplate = lazy(() => import("./pages/signTemplate"));
import ErrorImage from "./assets/images/error.png";
import CustomLoader from "components/customeLoader/CustomLoader";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <section className="page-error page--error">
      <div className="stars stars1" />
      <div className="stars stars2" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center ">
            <div className="position-relative">
              <h2 className="mb-0">Oops! Something went wrong</h2>
              {/* <img src={ErrorImage} alt="error" width="500" height="333" /> */}
              <h5>{error.message}</h5>
              <h5 className="mb-4">Try going back to our homepage.</h5>
              <button className="btn btn-common" onClick={resetErrorBoundary}>
                Go to Home page
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const App = () => {
  const { auth } = useAuth();

  const [error, setError] = useState(null);

  const ProtectedRoute = ({ children }) => {
    if (!auth?.isAuthenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        // <ErrorBoundary
        //   fallbackRender={({ error, resetErrorBoundary }) =>
        //     error ? (
        //       <div>
        //         <h2>An error occurred: {error.message}</h2>
        //         <button onClick={resetErrorBoundary}>Retry</button>
        //       </div>
        //     ) : null
        //   }
        //   onError={setError}
        //   onReset={() => (location.href = "/")}
        // >
        //   <Layout />
        // </ErrorBoundary>

        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(error, info) => {
            // Log error to an external service
            Sentry.captureException(error);
          }}
          onReset={() => {
            window.location.href = "/";
          }}
        >
          <Suspense fallback={<CustomLoader />}>
            <Layout />
          </Suspense>
        </ErrorBoundary>
      ),
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/search",
          element: <Search />,
        },
        {
          path: "/support",
          element: <Support />,
        },
        {
          path: "/connect",
          element: <Connect />,
        },
        {
          path: "/crowd-fund",
          element: <Community />,
        },
        {
          path: "/settings/:tab",
          element: (
            <ProtectedRoute>
              <Setting />
            </ProtectedRoute>
          ),
        },
        {
          path: "/notifications",
          element: <Notification />,
        },
        {
          path: "/profile/:username",
          element: <Profile />,
        },

        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "/terms-conditions",
          element: <TermsConditions />,
        },
        {
          path: "/privacy-Notice",
          element: <PrivacyNotice />,
        },
        {
          path: "/cookie-Policy",
          element: <CookiePolicy />,
        },
        {
          path: "/acceptable-Policy",
          element: <AcceptablePolicy />,
        },
        {
          path: "/inventory",
          element: <Inventory />,
        },
        {
          path: "/create-post",
          element: <CreatePost />,
        },
        {
          path: "/property-wanted-listing",
          element: <PropertyWantedListing />,
        },
        {
          path: "/update-wanted-listing",
          element: <PropertyWantedListing />,
        },
        {
          path: "/update-post",
          element: <CreatePost />,
        },
        {
          path: "/integrations",
          element: <IntegrationSetting />,
        },
        {
          path: "/setting",
          element: <EmailSetting />,
        },
        // {
        //   path: "/webapp",
        //   element: <WebappSettings />,
        // },
        {
          path: "/property/:title",
          element: <PostDetails />,
        },
        {
          path: "/crowd-fund/property/:title/",
          element: <PostDetails />,
        },
        {
          path: "/crowd-fund/property-detail/:title/",
          element: <PostDetails />,
        },
        {
          path: "/property-wanted/:title/",
          element: <PostDetails />,
        },
        {
          path: "/property-matched/:title",
          element: <PostDetails isMatched={true} />,
        },
        {
          path: "/property-detail/:title/",
          element: <PostDetails />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/test",
          element: <Test />,
        },
      ],
    },
    {
      path: "/otp-verification/:user_id/:token",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <OTP />
        </Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <Profiler
          id="connect_page"
          onRender={(
            id,
            phase,
            actualDuration,
            baseDuration,
            startTime,
            commitTime,
            interactions
          ) => {
            console.log({
              id,
              phase,
              actualDuration,
              baseDuration,
              startTime,
              commitTime,
              interactions,
            });
          }}
        >
          <Suspense fallback={<CustomLoader />}>
            <Login />
          </Suspense>
        </Profiler>
      ),
    },
    {
      path: "/register",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <Register pop_up={false} />
        </Suspense>
      ),
    },
    {
      path: "/sign/:template_id",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <SignTemplate />
        </Suspense>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <ForgotPassword />
        </Suspense>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <ForgotPassword />
        </Suspense>
      ),
    },
    {
      path: "/payment-confirmation/42irunefin094rr0nie21dwm0",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <PaymentConfirmation />
        </Suspense>
      ),
    },
    {
      path: "/create-password",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <CreatePassword />
        </Suspense>
      ),
    },

    {
      path: "*",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <PageNotFound />
        </Suspense>
      ),
    },
  ]);
  useEffect(() => {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
  }, []);
  return (
    <>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />

      <ToastContainer />
    </>
  );
};

export default App;
