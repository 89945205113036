import { uniqueId } from "../helpers/index";

export const features = [
  {
    id: 1,
    value: "laundry room",
    label: "Laundry Room",
  },
  {
    id: 2,
    value: "storage",
    label: "Storage",
  },
  {
    id: 3,
    value: "air conditioning",
    label: "Air Conditioning",
  },
  {
    id: 4,
    value: "heating",

    label: "Heating",
  },
  {
    id: 5,
    value: "balcony",
    label: "Balcony",
  },
  {
    id: 6,
    value: "double glazing",
    label: "Double Glazing",
  },
  {
    id: 7,
    value: "garage",
    label: "Garage",
  },
  {
    id: 8,
    value: "patio",
    label: "Patio",
  },
  {
    id: 9,
    value: "smart home",
    label: "Smart Home",
  },
];

export const amenities = [
  {
    id: 1,
    value: "gym or fitness center",
    label: "Gym or Fitness Center",
  },
  {
    id: 2,
    value: "pool",
    label: "Pool",
  },
  {
    id: 3,
    value: "garden/roof terrace",
    label: "Garden/Roof Terrace",
  },
  {
    id: 4,
    value: "broadband",
    label: "Broadband",
  },
  {
    id: 5,
    value: "dishwasher",
    label: "Dishwasher",
  },
  {
    id: 6,
    value: "disabled access",
    label: "Disabled Access",
  },
];


export const enumData = [
  "home page",
  "search page",
  "profile page",
  "crowd page",
  "verifaction page",
];

export const distance = {
  kilometers: [
    {
      id: 1,
      value: 5,
      lable: "+5 kilometers",
      sub_value: 6371,
    },
    {
      id: 2,
      value: 10,
      lable: "+10 kilometers",
      sub_value: 6371,
    },
    {
      id: 3,
      value: 20,
      lable: "+20 kilometers",
      sub_value: 6371,
    },
    {
      id: 4,
      value: 40,
      lable: "+40 kilometers",
      sub_value: 6371,
    },
    {
      id: 5,
      value: 60,
      lable: "+60 kilometers",
      sub_value: 6371,
    },
    {
      id: 6,
      value: 100,
      lable: "+100 kilometers",
      sub_value: 6371,
    },
  ],
  miles: [
    {
      id: 1,
      value: 5,
      lable: "+5 miles",
      sub_value: 3959,
    },
    {
      id: 2,
      value: 10,
      lable: "+10 miles",
      sub_value: 3959,
    },
    {
      id: 3,
      value: 20,
      lable: "+20 miles",
      sub_value: 3959,
    },
    {
      id: 4,
      value: 40,
      lable: "+40 miles",
      sub_value: 3959,
    },
    {
      id: 5,
      value: 60,
      lable: "+60 miles",
      sub_value: 3959,
    },
    {
      id: 6,
      value: 100,
      lable: "+100 miles",
      sub_value: 3959,
    },
  ],
};

export const sortOptions = [
  {
    id: 1,
    value: "created",
    sub_value: "DESC",
    label: "Most Recent",
  },
  {
    id: 2,
    value: "price",
    sub_value: "ASC",
    label: "Lowest Price",
  },
  {
    id: 3,
    value: "price",
    sub_value: "DESC",
    label: "Highest Price",
  },
];

export const status_options = [
  { id: 1, value: "available", label: "Available" },
  { id: 2, value: "Under Offer", label: "Under Offer" },
  { id: 3, value: "Sold", label: "Sold" },
  { id: 4, value: "Let", label: "Rented" },
];



export const BINANCE_CHAIN_ID = 56;
export const Pagelimit = 20;

export const POLYGON_CHAIN_ID = 137;
